const apiKeyKey = "dm-api-key";

const getApiKey = () => {
  let key = null;
  try {
    key = localStorage.getItem(apiKeyKey);
  } catch {}
  return key;
};

const setApiKey = (key: string) => {
  try {
    localStorage.setItem(apiKeyKey, key);
  } catch {}
};

const clearApiKey = () => {
  try {
    localStorage.removeItem(apiKeyKey);
  } catch {}
};

export { getApiKey, setApiKey, clearApiKey };
