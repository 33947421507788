import React, { useState, useEffect } from "react";
import { Container, Form, Header, Dropdown, Message } from "semantic-ui-react";
import ApiKeyPrompt from "./api_key_prompt";
import { createReferral, Specialties } from "./referral_service";
import { getApiKey, clearApiKey } from "./storage";

const App = () => {
  const [specialty, setSpecialty] = useState<string | undefined>();
  const [patientName, setPatientName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const [errorMessages, setErrorMessages] = useState(new Map<string, string>());
  const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
  const [creationError, setCreationError] = useState("");
  const [promptOpen, setPromptOpen] = useState(false);

  const validate = () => {
    const errors = new Map<string, string>();
    if (!specialty || specialty?.trim().length === 0) {
      errors.set("specialty", "Specialty is required");
    }
    if (patientName.trim().length === 0) {
      errors.set("patientName", "Patient name is required");
    }
    if (patientName.trim().length > 0 && !/[ ]/.test(patientName.trim())) {
      errors.set("patientName", "First and last are required");
    }
    if (mobileNumber.trim().length === 0 && email.trim().length === 0) {
      errors.set("mobileNumber", "Mobile number or email is required");
      errors.set("email", "Mobile number or email is required");
    }
    if (mobileNumber.trim().length > 0 && /[^0-9-]/.test(mobileNumber.trim())) {
      errors.set("mobileNumber", "A valid mobile number is required");
    }
    if (
      email.trim().length > 0 &&
      !/.+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+/.test(email.trim())
    ) {
      errors.set("email", "A valid email address is required");
    }
    setErrorMessages(errors);
    return errors.size === 0;
  };

  const onCreate = async () => {
    if (!validate()) {
      return;
    }

    setSaving(true);
    const apiKey = getApiKey();
    if (!apiKey) {
      setPromptOpen(true);
      return;
    }

    const response = await createReferral({
      apiKey,
      referral: { patientName, email, mobileNumber, specialty: specialty! },
    });
    setCreatedSuccessfully(response.success);
    if (!response.success) {
      setCreationError(response.message);
    }
    setSaving(false);
  };

  const getError = (key: string) => {
    if (errorMessages.has(key)) {
      return {
        content: errorMessages.get(key),
        pointing: "below",
      };
    }
    return undefined;
  };

  const onModalClose = (saved: boolean) => {
    setPromptOpen(false);
    if (saved) {
      onCreate();
    }
  };

  const clearKey = () => {
    clearApiKey();
    window.alert("Secret code reset.");
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <Container>
        <Header>DM Referrals</Header>
        <p>
          This site is used to create test DM referrals in the{" "}
          <a
            href="https://app-staging.picassomd.com/tools/internal_tools#referral_queue"
            target="_blank"
            rel="noopener noreferrer"
          >
            staging system
          </a>
          .
        </p>
        <p>
          You can provide your own email address and mobile number in order to
          receive the SMS/email notifying you of this referral as a patient.
        </p>
        <Form success={createdSuccessfully} error={!!creationError}>
          <Message success content="Test referral successfully created" />
          <Message
            error
            content={`Error creating test referral: ${creationError}`}
          />

          <Form.Field>
            <label>Specialty</label>
            <Dropdown
              selection
              required
              options={Specialties}
              value={specialty}
              onChange={(_e, { value }) => setSpecialty(value?.toString())}
              error={errorMessages.has("specialty")}
            />
          </Form.Field>
          <Form.Input
            label="Patient Name"
            type="text"
            value={patientName}
            onChange={(_e, { value }) => setPatientName(value)}
            error={getError("patientName")}
          />
          <Form.Input
            label="Mobile Number"
            type="text"
            value={mobileNumber}
            onChange={(_e, { value }) => setMobileNumber(value)}
            error={getError("mobileNumber")}
          />
          <Form.Input
            label="Email"
            type="text"
            value={email}
            onChange={(_e, { value }) => setEmail(value)}
            error={getError("email")}
          />
          <Form.Group inline>
            <Form.Button
              content="Create Test Referral"
              onClick={onCreate}
              color="blue"
              loading={saving}
            />
            <Form.Button content="Reset Secret Code" onClick={clearKey} />
          </Form.Group>
        </Form>
        <ApiKeyPrompt isOpen={promptOpen} onClose={onModalClose} />
      </Container>
    </div>
  );
};

export default App;
