type ReferralParams = {
  apiKey: string;
  referral: {
    patientName: string;
    email?: string;
    mobileNumber?: string;
    specialty: string;
  };
};

type CreateResponse =
  | {
      success: true;
    }
  | { success: false; message: string };

const SubmitUrl =
  "https://7vxpagi24uolon5izwrltfe2ce0jkbdh.lambda-url.us-east-1.on.aws/";

const createReferral = async (
  params: ReferralParams
): Promise<CreateResponse> => {
  try {
    const response = await fetch(SubmitUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    if (response.status === 401) {
      return { success: false, message: "Invalid secret code" };
    } else if (response.status === 500) {
      const parsed = await response.json();
      return { success: false, message: parsed.message };
    } else if (response.status !== 200) {
      return { success: false, message: "Unknown error" };
    }
    return response.json();
  } catch (err) {
    console.log(err);
    return { success: false, message: (err as Error).toString() };
  }
};

const Specialties = [
  { key: 1, text: "Cardiology", value: "Cardiology" },
  { key: 4, text: "Dermatology", value: "Dermatology" },
  { key: 6, text: "Endocrinology", value: "Endocrinology" },
  { key: 3, text: "Gastroenterology", value: "Gastroenterology" },
  { key: 12, text: "OB/GYN", value: "OB/GYN" },
  { key: 2, text: "Orthopedics", value: "Orthopedics" },
];

export { createReferral, Specialties };
