import React, { useState } from "react";
import { Modal, Form } from "semantic-ui-react";
import { setApiKey } from "./storage";

type Props = {
  isOpen: boolean;
  onClose: (saved: boolean) => void;
};

const ApiKeyPrompt = ({ isOpen, onClose }: Props) => {
  const [key, setKey] = useState("");

  const saveKey = () => {
    if (key.trim().length === 0) {
      return;
    }
    setApiKey(key);
    onClose(true);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose(false)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Form>
          <Form.Input
            label="Enter the secret key to create a test referral"
            type="text"
            required
            value={key}
            onChange={(_e, { value }) => setKey(value)}
          />
          <Form.Button
            color="blue"
            onClick={() => saveKey()}
            content="Save and create referral"
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ApiKeyPrompt;
